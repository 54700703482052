import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'ravimoshark.pages.clients.menuName',
    key: 'ravimoshark.clients',
    url: '/clients',
  },
  {
    title: 'ravimoshark.pages.articles.menuName',
    key: 'ravimoshark.articles',
    url: '/articles',
  },
  {
    title: 'ravimoshark.pages.tasks.menuName',
    key: 'ravimoshark.tasks',
    url: '/tasks',
    children: [
      {
        title: 'ravimoshark.pages.tasks.children.all',
        key: 'ravimoshark.tasks.all',
        url: '/tasks',
      },
      {
        title: 'ravimoshark.pages.tasks.children.treat',
        key: 'ravimoshark.tasks.treat',
        url: '/tasks?status=finished_by_technician',
      },
    ],
  },
  {
    title: 'ravimoshark.pages.technicians.menuName',
    key: 'ravimoshark.technicians',
    url: '/technicians',
  },
];
