import { httpApiMock } from '@app/api/mocks/http.api.mock';

httpApiMock.onPost('login').reply(() => {
  return [
    200,
    {
      token: 'bearerToken',
      user: {
        id: 1,
        firstName: 'Patricio',
        lastName: 'Perpetua',
        userName: '@patoperpetua',
        email: {
          name: 'patoperpetua@gmail.com',
          verified: false,
        },
        phone: {
          number: '+18143519459',
          verified: true,
        },
        sex: 'male',
        birthday: '01/26/2022',
        lang: 'en',
        country: 'GB',
        city: 'London',
        address1: '14 London Road',
        zipcode: 5211,
        website: 'altence.com',
        socials: {
          twitter: '@altence_team',
          facebook: 'https://facebook.com/groups/1076577369582221',
          linkedin: 'https://linkedin.com/company/altence',
        },
      },
    },
  ];
  // TODO: connect with API
  // return [401, { message: 'Invalid Credentials' }];
});

httpApiMock.onPost('signUp').reply(200);

httpApiMock.onPost('forgotPassword').reply(200);

httpApiMock.onPost('verifySecurityCode').reply(200);

httpApiMock.onPost('setNewPassword').reply(200);
