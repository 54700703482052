/* eslint-disable @typescript-eslint/ban-types */
import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export type DefaultType = Record<string, unknown | undefined>;

export type ApiCallResponse<T, U extends DefaultType = {}> = {
  data?: Partial<T>;
  error?: Error;
  loading: boolean;
  setData: Dispatch<SetStateAction<Partial<T | undefined>>>;
  reload: (args?: reloadArgs<U> | undefined) => void;
  reloadArgs?: reloadArgs<U>;
};

export type PromiseFn<T, U extends DefaultType = {}> = (args?: reloadArgs<U>) => Promise<T>;
export type reloadArgs<T extends DefaultType> = T & {
  limit?: number | undefined;
  page?: number | undefined;
};

function useApiCall<T, U extends DefaultType = {}>(
  promise: PromiseFn<T, U>,
  initValues?: Partial<T | undefined>,
): ApiCallResponse<T, U> {
  const [data, setData] = useState<Partial<T | undefined>>(initValues);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [reloadArgs, setReloadArgs] = useState<reloadArgs<U>>();

  useEffect(() => {
    let isMounted = true; // Add a variable to track whether the component is mounted or not
    async function fetchData() {
      setLoading(true);
      try {
        const response = await promise(reloadArgs);
        if (isMounted) setData(response);
      } catch (err) {
        console.log(err);
        if (isMounted) setError(err as Error);
      }
      if (isMounted) setLoading(false);
    }
    fetchData();
    // Add a cleanup function to cancel any pending requests
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, reloadArgs]);

  const reload = (args?: reloadArgs<U>, persistPrevArgs?: boolean) => {
    if (areObjectsEqual(args, reloadArgs)) return;
    if (persistPrevArgs)
      setReloadArgs((prev) => {
        let finalValue: reloadArgs<U> = {} as reloadArgs<U>;
        if (!!prev) finalValue = { ...prev };
        if (!!args) finalValue = { ...finalValue, ...args };

        // Delete empty args
        (!!finalValue ? Object.keys(finalValue).filter((key) => !finalValue[key]) : []).forEach(
          (key: keyof reloadArgs<U>) => (finalValue[key] = undefined as never),
        );

        return finalValue;
      });
    else setReloadArgs(args);
    setTrigger((prevTrigger) => !prevTrigger);
  };

  return { data, error, loading, setData, reload, reloadArgs };
}

function areObjectsEqual<U extends DefaultType>(obj1?: reloadArgs<U>, obj2?: reloadArgs<U>) {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false; // Return false if either parameter is not an object
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Return false if the objects have different number of properties
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false; // Return false if the values for the same key are not equal
    }
  }

  return true; // All properties have the same values, return true
}

export { useApiCall };
