import React from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import frFR from 'antd/lib/locale/fr_FR';
import esES from 'antd/lib/locale/es_ES';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { LanguageType } from '@app/interfaces/interfaces';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ClientDataProvider } from '@app/contexts/ClientDataProvider';
import { getClientsSelector } from '@app/api/ravimoshark/clients/table.api';
import { ArticlesApi, ClientsApi, Configuration } from '@app/api/ravimoshark/lib';
import { getAccessToken } from '@app/auth/authConfig';
import { ArticleDataProvider } from '@app/contexts/ArticleDataProvider';
import { getArticlesSelector } from '@app/api/ravimoshark/articles/table.api';
import { useAppInsightsFlush } from '@app/services/appInsights/useAppInsightsFlush';

const languageSelector = (language: LanguageType) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'de':
      return deDe;
    case 'fr':
      return frFR;
    case 'es':
      return esES;
  }
};

type AppProps = {
  pca?: IPublicClientApplication;
};

const App: React.FC<AppProps> = ({ pca }) => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  useAppInsightsFlush();

  const childrenApps = (
    <>
      <ClientDataProvider
        promise={() =>
          getClientsSelector(
            process.env.REACT_APP_API_USE_MOCK_DATA === 'true'
              ? undefined
              : new ClientsApi(
                  new Configuration({
                    basePath: process.env.REACT_APP_API_BASE_PATH,
                    accessToken: getAccessToken(),
                  }),
                ),
          )
        }
      >
        <ArticleDataProvider
          promise={() =>
            getArticlesSelector(
              process.env.REACT_APP_API_USE_MOCK_DATA === 'true'
                ? undefined
                : new ArticlesApi(
                    new Configuration({
                      basePath: process.env.REACT_APP_API_BASE_PATH,
                      accessToken: getAccessToken(),
                    }),
                  ),
            )
          }
        >
          <HelmetProvider>
            <ConfigProvider locale={languageSelector(language)}>
              <AppRouter />
            </ConfigProvider>
          </HelmetProvider>
        </ArticleDataProvider>
      </ClientDataProvider>
    </>
  );
  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      {pca ? <MsalProvider instance={pca}>{childrenApps}</MsalProvider> : { ...childrenApps }}
    </>
  );
};

export default App;
