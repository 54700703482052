import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null = null;

const connectionString = process.env.REACT_APP__APPLICATIONINSIGHTS_CONNECTION_STRING;
if (process.env.REACT_APP__APPLICATIONINSIGHTS_DISABLE === 'false' && connectionString && connectionString.length > 0) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      disableFlushOnBeforeUnload: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxPerfTracking: true,
      isBrowserLinkTrackingEnabled: true,
      autoTrackPageVisitTime: true,
      extensions: [reactPlugin],
      enableDebug: true,
    },
  });

  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags['ai.cloud.role'] = 'RenderingHost';
  });

  // Request interceptor
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      appInsights?.trackEvent(
        { name: 'API Call Request' },
        {
          target: config.url,
          data: JSON.stringify(config.data),
          params: config.params,
          // https://singletonsd.atlassian.net/browse/RVS-468
          // TODO: get the user
          // user: user?.email,
        },
      );

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      // Log the response
      appInsights?.trackEvent(
        { name: 'API Call Response' },
        {
          id: response.headers?.['X-Request-ID'] || '',
          target: response.config.url,
          data: JSON.stringify(response.data),
          responseCode: response.status,
          // https://singletonsd.atlassian.net/browse/RVS-468
          // TODO: get the user
          // user: user?.email,
        },
      );

      return response;
    },
    (error) => {
      // Log the error response
      if (error.response) {
        appInsights?.trackEvent(
          { name: 'API Call Error' },
          {
            id: error.response.headers?.['X-Request-ID'] || '',
            target: error.response.config.url,
            data: JSON.stringify(error.response.data),
            // TODO: get the user
            // https://singletonsd.atlassian.net/browse/RVS-468
            // user: user?.email,
          },
        );
      }

      return Promise.reject(error);
    },
  );
}

export { reactPlugin, appInsights };
