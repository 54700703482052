import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { useClientDataContext } from '@app/contexts/ClientDataProvider';
import { PageLoading } from '@app/components/common/PageLoading';
import { useArticleDataContext } from '@app/contexts/ArticleDataProvider';

// Ravimoshark Pages
const RavimosharkArticlesPage = React.lazy(() => import('@app/pages/ravimoshark/RavimosharkArticlesPage'));
const RavimosharkArticles = withLoading(RavimosharkArticlesPage);
const RavimosharkClientsPage = React.lazy(() => import('@app/pages/ravimoshark/RavimosharkClientsPage'));
const RavimosharkClients = withLoading(RavimosharkClientsPage);
const RavimosharkTasksPage = React.lazy(() => import('@app/pages/ravimoshark/RavimosharkTasksPage'));
const RavimosharkTasks = withLoading(RavimosharkTasksPage);
const TaskViewSelectedPage = React.lazy(() => import('@app/pages/ravimoshark/tasksPages/TaskViewSelectedPage'));
const TaskViewSelected = withLoading(TaskViewSelectedPage);
const RavimosharkTechniciansPage = React.lazy(() => import('@app/pages/ravimoshark/RavimosharkTechniciansPage'));
const RavimosharkTechnician = withLoading(RavimosharkTechniciansPage);

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));

const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const { loading } = useClientDataContext();
  const { loading: loadingArticles } = useArticleDataContext();

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return loading || loadingArticles ? (
    <PageLoading />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route path="/" element={<Navigate to="/tasks" />} />
          <Route path="/articles" element={<RavimosharkArticles />} />
          <Route path="/clients" element={<RavimosharkClients />} />
          <Route path="/tasks/view/:dateAppel" element={<TaskViewSelected />} />
          <Route path="/tasks" element={<RavimosharkTasks />} />
          <Route path="/technicians" element={<RavimosharkTechnician />} />

          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="logout" element={<LogoutFallback />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
