export interface UserModel {
  id: number;
  firstName: string;
  lastName: string;
  email: {
    name: string;
    verified: boolean;
  };
}

export const getName = (user: UserModel): string => `${(user.firstName || 'U').charAt(0)} ${user.lastName || 'N'}`;
export const getInitials = (user: UserModel): string =>
  getName(user)
    .split(' ')
    .map((n) => n.charAt(0))
    .join('');
