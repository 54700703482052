import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { RefreshClientData } from '@app/components/header/components/RefreshClientData/RefreshClientData';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}>
          {/* TODO: Add HeaderSearch functionality
          https://singletonsd.atlassian.net/browse/RVS-366
          */}
          {/* <HeaderSearch /> */}
        </Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={10} xxl={8}>
        {/* TODO: Add HeaderSearch functionality
          https://singletonsd.atlassian.net/browse/RVS-366
          */}
        {/* <HeaderSearch /> */}
      </Col>
    </>
  );

  return (
    <Row justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={12} xxl={12} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <Col>
                <RefreshClientData />
              </Col>
              <Col>
                <HeaderFullscreen />
              </Col>

              <Col>
                {/* TODO: [LAYOUT] Add Notifications Feature
            https://singletonsd.atlassian.net/browse/RVS-367 */}
                {/* <NotificationsDropdown /> */}
              </Col>

              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
