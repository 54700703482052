import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { MicrosoftAuthButton } from '@app/auth/MicrosoftAuthButton';

type LoginFormProps = {
  enableSignUp?: boolean;
};

export const LoginForm: React.FC<LoginFormProps> = ({ enableSignUp = false }) => {
  const { t } = useTranslation();

  const [, setLoading] = useState(false);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <BaseForm.Item noStyle>
          <MicrosoftAuthButton setLoading={setLoading} />
        </BaseForm.Item>
        {enableSignUp && (
          <Auth.FooterWrapper>
            <Auth.Text>
              {t('login.noAccount')}{' '}
              <Link to="/auth/sign-up">
                <Auth.LinkText>{t('common.here')}</Auth.LinkText>
              </Link>
            </Auth.Text>
          </Auth.FooterWrapper>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
