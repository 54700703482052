import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getFirstNameAndLastName, setUser } from '@app/store/slices/userSlice';
import { persistToken } from '@app/services/localStorage.service';
import { loginRequest } from '@app/auth/authConfig';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const account = useAccount();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (process.env.REACT_APP_AZURE_AD_DISABLE && process.env.REACT_APP_AZURE_AD_DISABLE === 'true') {
      console.warn('AZURE AD AUTH DISABLED');
      dispatch(
        setUser({
          token: 'token',
          id: 'test',
          ...getFirstNameAndLastName('Test Account'),
          userName: 'test@ravimoshark.com',
          email: {
            name: 'test@ravimoshark.com',
            verified: true,
          },
        }),
      );
      persistToken('token', new Date(new Date().getTime() + 10).toISOString());
      return;
    }
    if (isAuthenticated && !!account) {
      (async () => {
        const accessToken = await instance.acquireTokenSilent(loginRequest);
        dispatch(
          setUser({
            token: accessToken.accessToken,
            id: account.homeAccountId,
            ...getFirstNameAndLastName(account.name),
            userName: account.username,
            email: {
              name: account.username,
              verified: true,
            },
          }),
        );
        persistToken(accessToken.accessToken, accessToken.expiresOn?.toISOString() || '');
      })();
    } else {
      navigate('/auth/login');
    }
  }, [isAuthenticated, account, navigate, dispatch, instance]);

  return <>{children}</>;
};

export default RequireAuth;
