import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DownloadAppCard } from '@app/api/ravimoshark/DownloadAppCard';
import { Col, Row } from 'antd';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.login')}</PageTitle>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col>
          <LoginForm />
        </Col>
        <Col>
          <DownloadAppCard />
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
