import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MicrosoftIcon } from '@app/assets/icons/microsoft.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getFirstNameAndLastName, setUser } from '@app/store/slices/userSlice';
import { persistToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';
import { loginRequest } from '@app/auth/authConfig';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClientDataContext } from '@app/contexts/ClientDataProvider';

type MicrosoftAuthButtonProps = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MicrosoftAuthButton: React.FC<MicrosoftAuthButtonProps> = ({ setLoading }) => {
  const { t } = useTranslation();

  const { instance } = useMsal();
  const account = useAccount();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { reload } = useClientDataContext();

  const handleLogin = async () => {
    setLoading(true);
    await instance.loginRedirect(loginRequest).catch((err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
    return;
  };

  useEffect(() => {
    if (isAuthenticated && !!account) {
      (async () => {
        console.log('account', account);
        const accessToken = await instance.acquireTokenSilent(loginRequest);
        dispatch(
          setUser({
            token: accessToken.accessToken,
            id: account.homeAccountId,
            ...getFirstNameAndLastName(account.name),
            userName: account.username,
            email: {
              name: account.username,
              verified: true,
            },
          }),
        );
        reload();
        persistToken(accessToken.accessToken, accessToken.expiresOn?.toISOString() || '');
        navigate('/tasks');
      })();
    }
  }, [isAuthenticated, account, navigate, dispatch, instance, reload]);

  return (
    <Auth.SocialButton type="default" htmlType="button" onClick={handleLogin}>
      <Auth.SocialIconWrapper>
        <MicrosoftIcon />
      </Auth.SocialIconWrapper>
      {t('login.microsoftLink')}
    </Auth.SocialButton>
  );
};
