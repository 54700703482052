/* eslint-disable @typescript-eslint/ban-types */
import { ListesClients } from '@app/api/ravimoshark/lib';
import { ApiCallResponse, DefaultType, PromiseFn, useApiCall } from '@app/hooks/useApiCall';
import { AxiosResponse } from 'axios';
import { createContext, useContext } from 'react';

type ClientType = Pick<ApiCallResponse<AxiosResponse<ListesClients[]>>, 'reload' | 'loading' | 'data'> | undefined;

const ClientDataContext = createContext<ClientType>(undefined);

function useClientDataContext<U extends DefaultType>(): ApiCallResponse<AxiosResponse<ListesClients[]>, U> {
  const context = useContext(ClientDataContext);
  if (!context) {
    throw new Error('useClientDataContext must be used within ClientDataProvider');
  }
  return context as ApiCallResponse<Partial<AxiosResponse<ListesClients[]>>, U>;
}

type ClientDataProviderProps<T> = {
  promise: PromiseFn<T>;
  children: React.ReactNode;
};

function ClientDataProvider<T>({ promise, children }: ClientDataProviderProps<T>): JSX.Element {
  const props = useApiCall<T>(promise);

  return <ClientDataContext.Provider value={props}>{children}</ClientDataContext.Provider>;
}

export { ClientDataProvider, useClientDataContext };
