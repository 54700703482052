import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { QRCodeSVG } from 'qrcode.react';
import { notificationController } from '@app/controllers/notificationController';
import { Tooltip } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { CopyOutlined } from '@ant-design/icons';

type DownloadAppCardProps = {
  style?: React.CSSProperties | undefined;
  hideText?: boolean;
  qrSize?: number;
};

export const DownloadAppCard: React.FC<DownloadAppCardProps> = ({ style, hideText, qrSize = 250 }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ravimoshark.components.downloadAndroidApp' });
  const { t: tRoot } = useTranslation('translation');

  const url = process.env.REACT_APP_ANDROID_APP_URL;

  const handleCopy = () =>
    url &&
    navigator.clipboard.writeText(url).then(() => {
      notificationController.info({ message: tRoot('common.copied') });
    });

  return (
    <Auth.FormWrapper style={style}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Auth.FormTitle>{t('title')}</Auth.FormTitle>
        {url ? (
          <>
            {!hideText && (
              <Link to={url} target="_blank" style={{ whiteSpace: 'pre-line' }}>
                {t('text')}
              </Link>
            )}
            <Tooltip title={t('copy')}>
              <Button size="large" type="text" icon={<CopyOutlined />} onClick={handleCopy}>
                {t('copy')}
              </Button>
            </Tooltip>
            <QRCodeSVG value={url} style={{ paddingBlock: '16px' }} size={qrSize} />
          </>
        ) : (
          'Sorry property REACT_APP_ANDROID_APP_URL not set'
        )}
      </div>
    </Auth.FormWrapper>
  );
};
