import { msalInstance } from '@app/index';
import { deleteToken, persistToken, readToken } from '@app/services/localStorage.service';
import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_AZURE_AD_READ_SCOPE || ''],
};

export const getAccessToken = async (): Promise<string> => {
  let { token, expiresAt } = readToken();
  if (token && expiresAt) {
    const expiryTime = new Date(expiresAt);
    if (expiryTime > new Date()) {
      // Access token is still valid, return it
      return token;
    }
  }

  try {
    if (!msalInstance || !msalInstance.getActiveAccount()) return '';
    const response = await msalInstance?.acquireTokenSilent(loginRequest);
    token = response?.accessToken || '';
    expiresAt = response?.expiresOn?.toISOString() || '';

    persistToken(token, expiresAt);

    return token || '';
  } catch (error) {
    deleteToken();
    // Handle the error if token acquisition fails
    console.error('Failed to acquire token:', error);
    return '';
  }
};
