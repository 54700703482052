import {
  ArticlesApi,
  ArticlesControllerFindAll200Response,
  DataMigrationRequest,
  ListesPieces,
} from '@app/api/ravimoshark/lib';

import data from './response.json';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { mergeArrays } from '@app/utils/array.utils';

export const getArticles = (
  articleAPI?: ArticlesApi,
  limit?: number,
  page?: number,
): Promise<Partial<AxiosResponse<ArticlesControllerFindAll200Response>>> => {
  return articleAPI
    ? articleAPI.articlesControllerFindAll(undefined, undefined, undefined, limit, page)
    : new Promise((res) => {
        setTimeout(() => {
          res({ data: { ...data }, status: 200 });
        }, 1000);
      });
};

type migrateArticlesProps = {
  record: DataMigrationRequest;
} & articlesProps;
export const migrateArticles = ({
  applyChangesToData,
  record,
  articleAPI,
  setData,
}: migrateArticlesProps): Promise<Partial<AxiosResponse<ListesPieces[]>>> =>
  articleAPI
    ? articleAPI.migrationArticlesControllerCreate(record).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: mergeArrays(data?.data?.items || [], response.data, 'referenceArticle') },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          res({ status: 200, data: data.items });
        }, 1000);
      });

export const getArticlesSelector = (articleAPI?: ArticlesApi): Promise<Partial<AxiosResponse<ListesPieces[]>>> => {
  return articleAPI
    ? articleAPI.articlesControllerGetDropdownData()
    : new Promise((res) => {
        setTimeout(() => {
          res({ data: data.items, status: 200 });
        }, 1000);
      });
};

type deleteArticleProps = {
  referenceArticle: string;
} & articlesProps;

export const deleteArticles = ({
  referenceArticle,
  articleAPI,
  setData,
  applyChangesToData,
}: deleteArticleProps): Promise<Partial<AxiosResponse<void>>> => {
  return articleAPI
    ? articleAPI.articlesControllerRemove(referenceArticle).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: data?.data?.items?.filter((c) => c.referenceArticle !== referenceArticle) },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.filter((c) => c.referenceArticle !== referenceArticle),
            },
          }));
          res({ status: 200 });
        }, 1000);
      });
};

type editArticleProps = {
  referenceArticle: string;
} & addArticleProps;

export const editArticle = ({
  referenceArticle,
  record,
  articleAPI,
  setData,
  applyChangesToData,
}: editArticleProps): Promise<Partial<AxiosResponse<ListesPieces>>> => {
  return articleAPI
    ? articleAPI.articlesControllerUpdate(referenceArticle, record).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) => (c.referenceArticle === referenceArticle ? response.data : c)),
            },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) => (c.referenceArticle === referenceArticle ? record : c)),
            },
          }));
          res({ status: 200, data: record });
        }, 1000);
      });
};

type articlesProps = {
  articleAPI?: ArticlesApi;
  setData?: Dispatch<SetStateAction<Partial<AxiosResponse<ArticlesControllerFindAll200Response> | undefined>>>;
  applyChangesToData: boolean;
};

type addArticleProps = {
  record: ListesPieces;
} & articlesProps;

export const addArticle = ({
  record,
  articleAPI,
  setData,
  applyChangesToData,
}: addArticleProps): Promise<Partial<AxiosResponse<ListesPieces>>> => {
  return articleAPI
    ? articleAPI.articlesControllerCreate(record).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: [response.data, ...(data?.data?.items || [])] },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: [record, ...(data?.data?.items || [])] },
          }));
          res({ status: 200, data: record });
        }, 1000);
      });
};
