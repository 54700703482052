/* eslint-disable @typescript-eslint/ban-types */
import { ListesPieces } from '@app/api/ravimoshark/lib';
import { ApiCallResponse, DefaultType, PromiseFn, useApiCall } from '@app/hooks/useApiCall';
import { AxiosResponse } from 'axios';
import { createContext, useContext } from 'react';

type ArticleType = Pick<ApiCallResponse<AxiosResponse<ListesPieces[]>>, 'reload' | 'loading' | 'data'> | undefined;

const ArticleDataContext = createContext<ArticleType>(undefined);

function useArticleDataContext<U extends DefaultType>(): ApiCallResponse<AxiosResponse<ListesPieces[]>, U> {
  const context = useContext(ArticleDataContext);
  if (!context) {
    throw new Error('useArticleDataContext must be used within ArticleDataProvider');
  }
  return context as ApiCallResponse<Partial<AxiosResponse<ListesPieces[]>>, U>;
}

type ArticleDataProviderProps<T> = {
  promise: PromiseFn<T>;
  children: React.ReactNode;
};

function ArticleDataProvider<T>({ promise, children }: ArticleDataProviderProps<T>): JSX.Element {
  const props = useApiCall<T>(promise);

  return <ArticleDataContext.Provider value={props}>{children}</ArticleDataContext.Provider>;
}

export { ArticleDataProvider, useArticleDataContext };
