import React from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { HeaderActionWrapper } from '../../Header.styles';
import { useClientDataContext } from '@app/contexts/ClientDataProvider';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { ReloadOutlined } from '@ant-design/icons';

export const RefreshClientData: React.FC = () => {
  const { reload } = useClientDataContext();
  const { t } = useTranslation('translation', { keyPrefix: 'ravimoshark.headers' });
  const { isDesktop } = useResponsive();

  const text = t('refreshClientData');

  return (
    <HeaderActionWrapper>
      <Button onClick={() => reload()} icon={<ReloadOutlined />}>
        {isDesktop ? text : getLastWord(text)}
      </Button>
    </HeaderActionWrapper>
  );
};

function getLastWord(text: string) {
  const words = text.split(' ');

  if (words.length > 0) {
    // Access the last word in the array
    return words[words.length - 1];
  }

  return 'clients'; // or handle the case when there are no words
}
