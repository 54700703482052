export const mergeArrays = <T>(arr1: T[], arr2: T[], key: keyof T): T[] => {
  const mergedArray = [...arr1];

  arr2.forEach((obj2) => {
    const index = mergedArray.findIndex((obj1) => obj1[key] === obj2[key]);
    if (index === -1) {
      mergedArray.push(obj2);
    } else {
      mergedArray[index] = obj2;
    }
  });

  return mergedArray;
};
