import { appInsights } from '@app/services/appInsights/ApplicationInsightsService';
import { useEffect } from 'react';

export const useAppInsightsFlush = (): void => {
  useEffect(() => {
    const flushAppInsights = () => {
      appInsights?.flush();
    };

    window.addEventListener('pagehide', flushAppInsights);

    return () => {
      window.removeEventListener('pagehide', flushAppInsights);
    };
  }, []);
};
