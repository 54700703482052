import React from 'react';
import { Col, Row } from 'antd';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import { getInitials, getName } from '@app/domain/UserModel';
import styled from 'styled-components';

export const ProfileDropdown: React.FC = () => {
  const { isDesktop } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  return user ? (
    <Popover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <H6Round>{isDesktop ? getName(user).slice(0, 20) : getInitials(user)}</H6Round>
          <></>
        </Col>
      </S.ProfileDropdownHeader>
    </Popover>
  ) : null;
};

const H6Round = styled(H6)`
  background-color: #eaeaea; /* Set the background color */
  border-radius: 10px; /* Adjust the border radius to control the roundness */
  padding: 10px; /* Add padding to create space between the text and the background */
  display: inline-block; /* Ensure the background wraps around the text */
`;
