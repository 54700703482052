import {
  ClientsApi,
  ClientsControllerFindAll200Response,
  DataMigrationRequest,
  ListesClients,
} from '@app/api/ravimoshark/lib';

import data from './response.json';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { mergeArrays } from '@app/utils/array.utils';

export const getClients = (
  clientAPI?: ClientsApi,
  limit?: number,
  page?: number,
  group?: string,
): Promise<Partial<AxiosResponse<ClientsControllerFindAll200Response>>> =>
  clientAPI
    ? clientAPI.clientsControllerFindAll(undefined, undefined, group, limit, page)
    : new Promise((res) => {
        setTimeout(() => {
          res({ data: { ...data }, status: 200 });
        }, 1000);
      });

export const getClient = ({
  referenceClient,
  clientAPI,
  data,
}: { data?: Partial<AxiosResponse<ClientsControllerFindAll200Response> | undefined> } & deleteClientsProps): Promise<
  Partial<AxiosResponse<ListesClients>>
> => {
  return clientAPI
    ? clientAPI.clientsControllerFindOne(referenceClient)
    : new Promise((res) => {
        setTimeout(() => {
          res({ status: 200, data: data?.data?.items?.find((t) => t.referenceClient === referenceClient) });
        }, 1000);
      });
};

type migrateClientsProps = {
  record: DataMigrationRequest;
} & clientsProps;
export const migrateClients = ({
  applyChangesToData,
  record,
  clientAPI,
  setData,
}: migrateClientsProps): Promise<Partial<AxiosResponse<ListesClients[]>>> =>
  clientAPI
    ? clientAPI.clientsMigrationControllerCreate(record).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: mergeArrays(data?.data?.items || [], response.data, 'referenceClient') },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          res({ status: 200, data: data.items });
        }, 1000);
      });

export const getClientsSelector = (clientAPI?: ClientsApi): Promise<Partial<AxiosResponse<ListesClients[]>>> =>
  clientAPI
    ? clientAPI.clientsControllerGetDropdownData()
    : new Promise((res) => {
        setTimeout(() => {
          res({ data: data.items, status: 200 });
        }, 1000);
      });

type deleteClientsProps = {
  referenceClient: string;
} & clientsProps;

export const deleteClients = ({
  referenceClient,
  clientAPI,
  setData,
  applyChangesToData,
}: deleteClientsProps): Promise<Partial<AxiosResponse<void>>> => {
  return clientAPI
    ? clientAPI.clientsControllerRemove(referenceClient).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: data?.data?.items?.filter((c) => c.referenceClient !== referenceClient) },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: data?.data?.items?.filter((c) => c.referenceClient !== referenceClient) },
          }));
          res({ status: 200 });
        }, 1000);
      });
};

type editClientsProps = {
  referenceClient: string;
} & addClientProps;

export const editClient = ({
  referenceClient,
  record,
  clientAPI,
  setData,
  applyChangesToData,
}: editClientsProps): Promise<Partial<AxiosResponse<ListesClients>>> => {
  return clientAPI
    ? clientAPI.clientsControllerUpdate(referenceClient, record).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) => (c.referenceClient === referenceClient ? response.data : c)),
            },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: {
              ...data?.data,
              items: data?.data?.items?.map((c) => (c.referenceClient === referenceClient ? record : c)),
            },
          }));
          res({ status: 200, data: record });
        }, 1000);
      });
};

type clientsProps = {
  clientAPI?: ClientsApi;
  setData?: Dispatch<SetStateAction<Partial<AxiosResponse<ClientsControllerFindAll200Response> | undefined>>>;
  applyChangesToData: boolean;
};

type addClientProps = {
  record: ListesClients;
} & clientsProps;

export const addClient = ({
  record,
  clientAPI,
  setData,
  applyChangesToData,
}: addClientProps): Promise<Partial<AxiosResponse<ListesClients>>> => {
  return clientAPI
    ? clientAPI.clientsControllerCreate(record).then((response) => {
        applyChangesToData &&
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: [response.data, ...(data?.data?.items || [])] },
          }));
        return response;
      })
    : new Promise((res) => {
        setTimeout(() => {
          setData?.((data) => ({
            ...data,
            data: { ...data?.data, items: [record, ...(data?.data?.items || [])] },
          }));
          res({ status: 200, data: record });
        }, 1000);
      });
};
